<template>
  <div id="periodoCiclo">
    <!-- language="es" -->
    <date-picker
      v-model="periodo"
      type="month"
      placeholder="Seleccionar mes"
    />
  </div>
</template>

<script>
// import { es } from 'vuejs-datepicker/dist/locale'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/es'

import 'vue2-datepicker/index.css'

export default {
  name: 'PeriodoCiclo',
  components: { DatePicker },
  props: ['value'],
  data() {
    return {
      // es,
      periodo: null,
    }
  },
  watch: {
    periodo(event) {
      // console.log(event)
      this.$emit('input', event)
    },
  },
  mounted() {
    this.periodo = this.value
  },
}
</script>

<style></style>
