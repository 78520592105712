<template>
  <div>
    <div class="my-2">
      <!-- <b-button variant="primary">
        Filtrar
      </b-button> -->
      <b-button variant="primary">
        Añadir
      </b-button>
    </div>

    <b-table
      :fields="fields"
      hover
      :items="items"
    >
      <template #cell(actions)="row">
        <b-button
          size="sm"
          class="mr-1 my-2"
          @click="editAction(row)"
        >
          Editar
        </b-button>
        <b-button
          class=""
          size="sm"
          @click="deleteAction(row)"
        >
          Eliminar
        </b-button>
      </template>
    </b-table>

    <b-form
      @submit="onSubmit"
      @reset="onReset"
    >
      <div
        v-for="(field, key) in fields"
        :key="key"
      >
        <div v-if="!field.noForm">
          <b-form-group
            :id="field.key"
            :label="field.label"
            :label-for="field.key"
            :description="field.description"
          >
            <b-form-input
              v-if="validateInputType(field.type)"
              :id="field.key"
              v-model="form[field.key]"
              :type="field.type"
              :placeholder="field.placeholder"
              required
            />
            <v-select
              v-if="field.type === 'boolean'"
              :options="[
                { label: 'Activo', code: true },
                { label: 'Inctivo', code: false },
              ]"
            />
          </b-form-group>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
export default {
  name: 'ArqCrud',
  props: ['fields', 'items'],
  data() {
    return {
      form: {},
      formType: [
        'text',
        'number',
        'email',
        'password',
        'search',
        'url',
        'tel',
        'date',
        'time',
        'range',
        'color',
      ],
    }
  },
  computed: {},
  methods: {
    validateInputType(type) {
      // console.log('type', type)
      const res = this.formType.includes(type.toLowerCase())
      // console.log('res', res)
      return res
    },
    onReset() {},
    onSubmit() {},
  },
}
</script>

<style></style>
