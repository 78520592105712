<template>
  <div>
    <table>
      <thead>
        <tr>
          <th
            v-for="(h, i) in head"
            :key="i"
            style="text-align:center"
          >
            {{ h }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, i) in rows"
          :key="i"
        >
          <td
            v-for="(col, j) in row"
            :key="j"
          >
            <div class="th-col">
              {{ head[j] }}
            </div>
            <div class="td-val">
              <slot
                :data="{ value: col, row }"
                :name="nameCol(head[j])"
              >
                <div>
                  {{ col }}
                </div>
              </slot>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: ['head', 'rows'],
  methods: {
    nameCol(str) {
      const strReturn = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      return strReturn
        .replaceAll(' ', '_')
        .replaceAll('Ñ', 'N')
        .replaceAll('ñ', 'n')
    },
  },
}
</script>
<style scoped>
/*
Generic Styling, for Desktops/Laptops
*/
table {
  width: 100%;
  border-collapse: collapse;
}
/* Zebra striping */
tr:nth-of-type(odd) {
  background: #eee;
}
td .th-col {
  display: none;
}
th {
  background: rgb(189, 189, 189);
  color: rgb(255, 255, 255);
  font-weight: bold;
}
td,
th {
  padding: 6px;
  border: 1px solid #ccc;
  text-align: left;
}

/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }
  td .th-col {
    display: flex;
    font: bold;
    text-align: left;
    width: 50%;
  }
  td {
    display: flex;
  }
  td .td-val {
    width: 50%;
    text-align: right;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #ccc;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
  }
}
</style>
