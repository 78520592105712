import Vue from 'vue'
import Vuex from 'vuex'
import excel from 'vue-excel-export'
// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import typeDocuments from './type-documents'
import client from './client'

Vue.use(Vuex)
Vue.use(excel)
export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    typeDocuments,
    client,
  },
  strict: process.env.DEV,
})
