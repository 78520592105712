<template>
  <div>
    <input
      :value="value"
      @input="$emit('imput', $event.target.value)"
    >
  </div>
</template>

<script>
export default {
  name: 'ArqContrato',
  props: {},
  data() {
    return {}
  },
  created() {},
  methods: {},
}
</script>

<style lang="scss" scoped></style>
