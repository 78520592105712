import Vue from 'vue'
import VueRouter from 'vue-router'

import '@/libs/acl'
import { canNavigate, refreshToken } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        resource: 'home',
        action: 'read',
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/config',
      name: 'config',
      component: () => import('@/views/Config.vue'),
      meta: {
        resource: 'home',
        action: 'read',
        pageTitle: 'Config',
        breadcrumb: [
          {
            text: 'Config',
            active: true,
          },
        ],
      },
    },
    {
      path: '/gestor-turnos',
      name: 'gestor-turnos',
      component: () => import('@/views/GestorTurnos.vue'),
      meta: {
        resource: 'home',
        action: 'read',
        pageTitle: 'Gestor De Turnos',
        breadcrumb: [
          {
            text: 'gestor de turnos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/modules',
      name: 'modules',
      component: () => import('@/views/module/Module.vue'),
      meta: {
        resource: 'home',
        action: 'read',
        pageTitle: 'Config',
        breadcrumb: [
          {
            text: 'Config',
            active: true,
          },
        ],
      },
    },
    {
      path: '/redirect-to-post/:urlPost/:keys/:values',
      name: 'redirect-to-post',
      component: () => import('@/views/procesos/RedirectToPost.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/portal-web/:kiosco?',
      name: 'portal-web',
      component: () => import('@/views/PortalWeb.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
        redirectIfLoggedIn: true,
      },
      children: [
        {
          path: '/',
          name: 'portal-modules',
          component: () => import('@/views/portal/PortalModules.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            redirectIfLoggedIn: true,
          },
        },
        {
          path: '/module/:id',
          name: 'portal-modules-child',
          component: () => import('@/views/portal/ChildrenModules.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            redirectIfLoggedIn: true,
          },
        },
        {
          path: '/portal-turno/:type',
          name: 'portal-turno',
          component: () => import('@/views/portal/PortalTurnoVirtual.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            redirectIfLoggedIn: true,
          },
        },
        {
          path: '/portal-register',
          name: 'portal-register',
          component: () => import('@/views/portal/PortalRegister.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            redirectIfLoggedIn: true,
          },
        },
        {
          path: '/portal-formulario-proceso/:process',
          name: 'portal-formulario-proceso',
          component: () => import('@/views/portal/PortalForm.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            redirectIfLoggedIn: true,
          },
        },
        {
          path: '/portal-impresion-duplicados',
          name: 'portal-impresion-duplicados',
          component: () => import('@/views/portal/procedures/DescargueDuplicados.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            redirectIfLoggedIn: true,
          },
        },
        {
          path: '/portal-impresion-duplicados-kiosco',
          name: 'portal-impresion-duplicados-kiosco',
          component: () => import('@/views/portal/procedures/DescargueDuplicadoskiosco.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            redirectIfLoggedIn: true,
          },
        },
        {
          path: '/visualizacion-historico',
          name: 'portal-visualizacion-historico',
          component: () => import('@/views/portal/procedures/ConsultaHistorico.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            redirectIfLoggedIn: true,
          },
        },
        {
          path: '/generar-cupon',
          name: 'portal-generar-cupon',
          component: () => import('@/views/portal/procedures/GenerarCupon.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            redirectIfLoggedIn: true,
          },
        },
        {
          path: '/visualizacion-cartera',
          name: 'portal-visualizacion-cartera',
          component: () => import('@/views/portal/procedures/ConsultaCartera.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            redirectIfLoggedIn: true,
          },
        },
        {
          path: '/portal-visualizar-grafico/:process',
          name: 'portal-visualizar',
          component: () => import('@/views/portal/PortalViewConsumos.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            redirectIfLoggedIn: true,
          },
        },
        {
          path: '/simulacro-financiacion',
          name: 'simulacro-financiacion',
          component: () => import('@/views/portal/procedures/SimulacroFinanciacion.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            redirectIfLoggedIn: true,
          },
        },
        {
          path: '/cambio-direccion',
          name: 'cambio-direccion',
          component: () => import('@/views/portal/procedures/cambioDireccion.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            redirectIfLoggedIn: true,
          },
        },
        {
          path: '/cambio-direccion-entrega',
          name: 'cambio-direccion-entrega',
          component: () => import('@/views/portal/procedures/cambioDireccionEntrega.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            redirectIfLoggedIn: true,
          },
        },
        {
          path: '/cambio-tercero',
          name: 'cambio-tercero',
          component: () => import('@/views/portal/procedures/cambioTercero.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            redirectIfLoggedIn: true,
          },
        },
        {
          path: '/reclamaciones-pqr',
          name: 'reclamaciones-pqr',
          component: () => import('@/views/portal/procedures/ReclamacionesPQR.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            redirectIfLoggedIn: true,
          },
        },
        {
          path: '/reconexion-servicios',
          name: 'reconexion-servicios',
          component: () => import('@/views/portal/procedures/ReconexionServicios.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            redirectIfLoggedIn: true,
          },
        },
        {
          path: '/reinstalacion-servicios',
          name: 'reinstalacion-servicios',
          component: () => import('@/views/portal/procedures/ReinstalacionServicios.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            redirectIfLoggedIn: true,
          },
        },
        {
          path: '/formulario-pqr',
          name: 'formulario-pqr',
          component: () => import('@/views/portal/FormularioPQR.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            redirectIfLoggedIn: true,
          },
        },
        {
          path: '/suspension',
          name: 'suspension',
          component: () => import('@/views/portal/procedures/Suspension.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            redirectIfLoggedIn: true,
          },
        },
        {
          path: '/reactivacion-servicio',
          name: 'reactivacion-servicio',
          component: () => import('@/views/portal/procedures/ReactivacionServicio.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            redirectIfLoggedIn: true,
          },
        },
        {
          path: '/instalacion-servicio/:mode',
          name: 'instalacion-servicio',
          component: () => import('@/views/portal/procedures/instalacionServicio.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            redirectIfLoggedIn: true,
          },
        },
      ],
    },

    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/pages/not-authorized',
      name: 'not-authorized',
      // ! Update import path
      component: () => import('@/views/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '/',
      redirect: 'login',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  refreshToken()
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    // ! We updated login route name here from `auth-login` to `login` in starter-kit
    if (!isLoggedIn && to.path !== '/login') return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.roles[0] : null))
  }

  return next()
})

export default router
