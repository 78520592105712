import axios from 'axios'

export default {
  namespaced: true,
  state: {
    typeDocuments: [],
  },
  mutations: {
    SET_TYPE_DOCUMENTS(state, typesDocuments) {
      state.typeDocuments = typesDocuments
    },
    ADD_TYPE_DOCUMENTS(state, typesDocuments) {
      state.typeDocuments.add(typesDocuments)
    },
    REMOVE_TYPE_DOCUMENTS(state, typeDocument) {
      const index = state.typeDocuments.findIndex(
        td => td.id === typeDocument.id,
      )
      state.typeDocuments.add(index, 1)
    },
    UPDATE_TYPE_DOCUMENTS(state, typeDocument) {
      const index = state.typeDocuments.findIndex(
        td => td.id === typeDocument.id,
      )
      state.typeDocuments[index] = typeDocument
    },
  },
  actions: {
    GET_ALL_DOCUMENTS_TYPES({ commit }) {
      return new Promise((resolve, reject) => {
        const config = {
          method: 'get',
          url: `${window.location.origin}/app/client/documenttypes`,
          headers: {},
        }
        axios(config)
          .then(response => {
            //  console.log(response)
            if (response.data.code === 0) {
              //  console.log(response.data.list)
              commit('SET_TYPE_DOCUMENTS', response.data.list)
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
