<template>
  <div style="background: rgb(165, 165, 165, 0.6)">
    <center>
      <input
        v-model.number="page"
        type="number"
        style="width: 5em"
      >
      <b
        class="mx-2"
      >/ <b class="mx-2">{{ numPages }} </b></b>
      <a href="#">
        <feather-icon
          v-if="!noPrint"
          class="mx-2"
          size="24"
          icon="PrinterIcon"
          @click="$refs.pdf.print()"
        />
      </a>
      <a
        :href="src"
        :download="name + '.pdf'"
      >
        <feather-icon
          class="my-2"
          size="24"
          icon="DownloadIcon"
        /></a>
    </center>
    <div style="margin: 20px; border: black 4px solid; ">
      <div
        v-if="loadedRatio > 0 && loadedRatio < 1"
        style="background-color: green; color: white; text-align: center; margin: 20px"
      />
      <pdf
        v-if="show"
        ref="pdf"
        :src="src"
        :page="pageReal"
        :rotate="rotate"
        @progress="loadedRatio = $event"
        @error="error"
        @num-pages="numPages = $event"
        @link-clicked="page = $event"
      />
    </div>
  </div>
</template>
<script>
import pdf from 'vue-pdf'

export default {
  components: {
    pdf,
  },
  props: {
    name: { type: String, default: 'file' },
    src: { type: String, default: '' },
    noPrint: { type: Boolean, default: false },
  },
  data() {
    return {
      show: true,
      loadedRatio: 0,
      pageReal: 1,
      page: 1,
      numPages: 0,
      rotate: 0,
    }
  },
  watch: {
    page(val, old) {
      if (!val) {
        this.pageReal = old
      } else {
        this.pageReal = val
      }
    },
  },
  methods: {
    download() {
      const file = new Blob([this.src.split(',')[1]], {
        type: 'application/pdf',
      })
      // Build a URL from the file
      const fileURL = URL.createObjectURL(file)
      // Open the URL on new Window
      window.open(fileURL)
    },
    password(updatePassword) {
      updatePassword(prompt('password is "test"'))
    },
    error() {
      //  console.log(err)
    },
  },
}
</script>
<style scoped lang="css">
a {
  color: black;
}
a:visited {
  color: black;
}
a:hover {
  color: black;
}
feather-icon {
  color: black !important;
}
</style>
