import qs from 'qs'
import axios from 'axios'
import ability from './ability'

export const canNavigate = to => to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))

export const _ = undefined

export const refreshToken = () => {
  const realm = process.env.VUE_APP_KEYCLOAK_REALM
  const tokenRefresh = localStorage.access_token_refresh_portal
  if (localStorage.access_token_refresh_portal) {
    const data = qs.stringify({
      client_id: 'epa-client',
      grant_type: 'refresh_token',
      refresh_token: tokenRefresh,
    })

    axios
      .post(`/auth/realms/${realm}/protocol/openid-connect/token`, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(res => {
        localStorage.access_token_portal = res.data.access_token
      })
      .catch(() => {
        localStorage.removeItem('client_portal')
        localStorage.removeItem('access_token_portal')
        localStorage.removeItem('access_token_refresh_portal')
        window.location.reload()
      })
  }
}
