import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import ArqCrud from '@core/components/arq/ArqCrud.vue'
import ArqPeriodo from '@core/components/arq/ArqPeriodo.vue'
import ArqContrato from '@core/components/arq/ArqContrato.vue'
import ArqPdf from '@core/components/arq/ArqPdf.vue'
import ArqPreviewsPage from '@core/components/arq/ArqPreviusPage.vue'
import ArqTable from '@core/components/arq/ArqTable.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import VueApexCharts from 'vue-apexcharts'
import pdf from 'vue-pdf'
import VueSweetalert2 from 'vue-sweetalert2'
// If you don't need the styles, do not connect
Vue.component('ArqTable', ArqTable)
Vue.use(VueSweetalert2)
Vue.use(VueApexCharts)
Vue.component('pdf', pdf)
Vue.component('apexchart', VueApexCharts)
Vue.component('ArqContrato', ArqContrato)
Vue.component('ArqPdf', ArqPdf)
Vue.component('v-select', vSelect)
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(ArqCrud.name, ArqCrud)
Vue.component('ArqPeriodo', ArqPeriodo)
Vue.component('ArqPreviewsPage', ArqPreviewsPage)
