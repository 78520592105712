import axios from 'axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {
    client: localStorage.client_portal
      ? JSON.parse(localStorage.client_portal)
      : {},
    realmClient: process.env.VUE_APP_KEYCLOAK_REALM,
    contratos: [],
  },
  mutations: {
    SET_CONTRACTS(state, contracts) {
      state.contratos = contracts
    },
    SET_CLIENT(state, typesDocuments) {
      state.typeDocuments = typesDocuments
    },
    REMOVE_CLIENT(state) {
      state.typeDocuments = null
    },
  },
  actions: {
    LOGIN_CLIENT(store, credentials) {
      //  console.log(store)
      const realm = store.state.realmClient
      const data = qs.stringify({
        client_id: 'epa-client',
        grant_type: 'password',
        username: credentials.username,
        password: credentials.password,
      })
      return new Promise((resolve, reject) => {
        axios
          .post(`/auth/realms/${realm}/protocol/openid-connect/token`, data, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          })
          .then(res => {
            localStorage.access_token_portal = res.data.access_token
            localStorage.access_token_refresh_portal = res.data.refresh_token
            axios
              .get(`/app/person/info/byEmail?email=${credentials.username}`)
              .then(resDataUser => {
                store.commit('SET_CLIENT', resDataUser.data.object)
                if (resDataUser.data.code === 0) {
                  const client = JSON.stringify(resDataUser.data.object)
                  localStorage.client_portal = client
                  store.dispatch(
                    'GET_CLIENT_CONTRACTS',
                    resDataUser.data.object,
                  )
                  resolve(resDataUser.data.object)
                } else {
                  reject(resDataUser.data)
                }
              })
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    LOGOUT_CLIENT({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          commit('REMOVE_CLIENT', {})
          localStorage.removeItem('client_portal')
          localStorage.removeItem('access_token_portal')
          localStorage.removeItem('access_token_refresh_portal')
          resolve()
        } catch (err) {
          reject(err)
        }
      })
    },
    GET_CLIENT_CONTRACTS({ commit }, client) {
      //  console.log('client', client)
      if (client.idtipodocumento && client.identificacion) {
        axios
          .get(
            `/client/contracts/${client.idtipodocumento}/${client.identificacion}`,
          )
          .then(res => {
            if (res.data.code === 0) {
              commit('SET_CONTRACTS', res.data.list)
            }
          })
      }
    },
    SAVE_CLIENT({ commit }, user) {
      return new Promise((resolve, reject) => {
        //  console.log(user)
        const data = JSON.stringify(user)
        const config = {
          method: 'post',
          url: '/app/client/register',
          headers: {
            'Content-Type': 'application/json',
          },
          data,
        }
        axios(config)
          .then(response => {
            //  console.log('response', response)
            if (response.data.code === 0) {
              resolve(response)
              commit('', response)
            } else {
              reject(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
